import React from "react"
import Layout from "../../components/member/Layout"
import Album from "../../components/member/Album"

export default () => {
  const pageSlug = "album"
  const pageTitle = "アルバム｜箕澤屋クラウド"
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Album />
    </Layout>
  )
}
