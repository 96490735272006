import React, { useEffect, useState, cloneElement } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import clsx from "clsx"
import {
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import HomeIcon from "@material-ui/icons/Home"
import ShoppingIcon from "@material-ui/icons/ShoppingBasket"
import CircularProgress from "@material-ui/core/CircularProgress"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import AccountCircle from "@material-ui/icons/AccountCircle"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import firebase from "../../utils/firebase"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum"
import EventNoteIcon from "@material-ui/icons/EventNote"
import LocalActivity from "@material-ui/icons/LocalActivity"
import BeenhereIcon from "@material-ui/icons/Beenhere"
import "fontsource-noto-sans-jp"
import "fontsource-hammersmith-one"
import "fontsource-noto-sans"

const drawerWidth = 240
const iconColor = "#262b35"
const useStyles = makeStyles(theme => ({
  loading: {
    marginTop: "100px",
    textAlign: "center",
    width: "100%",
  },
  root: {
    background: "#F0F0F0",
    minHeight: "100vh",
    display: "flex",
    fontFamily: "Noto Sans JP",
    lineHeight: "1.6",
    letterSpacing: "0.02em",
  },
  logo: {
    color: "#242424",
    fontFamily: "Hammersmith One",
    fontSize: "1.25rem",
    transition: "opacity .3s",
    "&:hover": {
      textDecoration: "none",
      opacity: "0.7",
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 2px 5px -2px rgba(0,0,0,0.05)",
    background: "#f8f8f8",
  },
  toolBar: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    background: "#349681",
    color: "#fff",
    borderRadius: "0",
    width: "68px",
    height: "68px",
    "&:hover": {
      background: "#30a58c",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  rightBar: {
    marginLeft: 0,
  },
  icon: {
    fill: iconColor,
    fontSize: "1.4rem",
  },
  iconColor: {
    fill: iconColor,
  },
  accountButton: {
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
    },
  },
}))

export default function PersistentDrawerLeft(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [loading] = useState(false)
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const { children, pageSlug, pageTitle } = props
  const menuId = "profile-menu"
  const isMenuOpen = Boolean(anchorEl)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    firebase.auth().signOut()
  }

  const handleProfileMenuOpen = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // custom theme
  let customTheme = createMuiTheme({
    palette: {
      primary: {
        // main: "#384050",
        // main: "#283b4c",
        // main: "#1f949b",
        main: "#349681",
      },
      secondary: {
        main: "#f95959",
        // main: "#0fb1bb",
      },
    },
    typography: {
      fontFamily: ["Noto Sans", "Noto Sans JP"],
      h1: {
        fontFamily: ["Noto Sans", "Noto Sans JP"],
      },
      h2: {
        fontFamily: ["Noto Sans", "Noto Sans JP"],
      },
      h3: {
        fontFamily: ["Noto Sans", "Noto Sans JP"],
      },
      h4: {
        fontFamily: ["Noto Sans", "Noto Sans JP"],
      },
    },
  })
  customTheme = responsiveFontSizes(customTheme)

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      elevation={1}
    >
      <MenuItem
        onClick={e => {
          handleMenuClose()
          window.location.replace("/member/profile")
        }}
      >
        アカウント設定
      </MenuItem>
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  )

  const loginRequired = () => {
    firebase.auth().onAuthStateChanged(function(u) {
      var db = firebase.firestore()
      if (u) {
        var memberUser = u.providerData.find(prov => {
          return prov.providerId === "password"
        })
        if (memberUser) {
          db.collection("users")
            .doc(u.uid)
            .get()
            .then(doc => {
              let profile = doc.data()
              const { uid, email, displayName, emailVerified } = u
              setUser({ uid, email, displayName, emailVerified, ...profile })
            })
          return
        }
      }
      setUser(null)
    })
  }

  // componentDidMount
  useEffect(() => {
    loginRequired()
  }, [])

  return (() => {
    if (user === null) {
      window.location.replace("/member/login")
      return null
    } else if (Object.keys(user).length === 0) {
      return (
        <ThemeProvider theme={customTheme}>
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        </ThemeProvider>
      )
    } else {
      return (
        <>
          <Helmet
            bodyAttributes={{
              class: pageSlug,
            }}
            defaultTitle={pageTitle}
          />
          <ThemeProvider theme={customTheme}>
            <div className={classes.root}>
              <Dialog open={loading}>
                <DialogContent>
                  <CircularProgress />
                </DialogContent>
              </Dialog>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar className={classes.toolBar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Link to="/member" noWrap className={classes.logo}>
                    MISAWAYA CLOUD (β)
                  </Link>
                  <div className={classes.grow} />
                  <Button
                    onClick={handleProfileMenuOpen}
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    className={`${classes.iconColor} ${classes.accountButton}`}
                  >
                    <AccountCircle className={classes.iconColor} />
                    <ArrowDropDownIcon />
                  </Button>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </div>
                <List>
                  <ListItem button key="home" component="a" href="/member">
                    <ListItemIcon>
                      <HomeIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="ホーム" />
                  </ListItem>

                  <ListItem
                    button
                    key="events"
                    component="a"
                    href="/member/events"
                  >
                    <ListItemIcon>
                      <EventNoteIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="イベント" />
                  </ListItem>

                  <ListItem
                    button
                    key="album"
                    component="a"
                    href="/member/album"
                  >
                    <ListItemIcon>
                      <PhotoAlbumIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="アルバム" />
                  </ListItem>

                  <ListItem
                    button
                    key="theme"
                    component="a"
                    href="/member/group"
                  >
                    <ListItemIcon>
                      <LocalActivity className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="グループ" />
                  </ListItem>

                  <ListItem button key="shop" component="a" href="/member/shop">
                    <ListItemIcon>
                      <ShoppingIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="ショップ" />
                  </ListItem>

                  <ListItem
                    button
                    key="reservation"
                    component="a"
                    href="/member/reservation"
                  >
                    <ListItemIcon>
                      <CalendarIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="施設利用" />
                  </ListItem>

                  <ListItem
                    button
                    key="policy"
                    component="a"
                    href="/member/policy"
                  >
                    <ListItemIcon>
                      <BeenhereIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary="活動ポリシー" />
                  </ListItem>
                </List>
              </Drawer>
              <main
                className={clsx(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                <div className={classes.drawerHeader} />
                {cloneElement(children, { user: user })}
              </main>
              {renderProfileMenu}
            </div>
          </ThemeProvider>
        </>
      )
    }
  })()
}
