import React from "react"
import styles from "../../assets/scss/member/Album.module.scss"
import Fade from "react-reveal/Fade"

export default () => {
  // const ref = useRef([])

  // const gallery = []

  // for (let i = 1; i <= 11; ++i) {
  //   gallery.push(
  //     <Fade delay={i * 50} key={i}>
  //       <div className={`album__item ${styles.album__item}`}>
  //         <img
  //           src={require(`../../assets/images/gallery/gallery${i}.jpg`)}
  //           alt=""
  //           ref={el => (ref.current[i] = el)}
  //         />
  //         {/* {console.log(ref.current)} */}
  //         {/* {(() => {
  //         console.log(ref.current[i])
  //         // const height = ref.current[i]
  //         // console.log(height)
  //       })()} */}
  //       </div>
  //     </Fade>
  //   )
  // }

  const images = [
    { src: "/images/gallery/gallery1.jpg", span: 26 },
    { src: "/images/gallery/gallery2.jpg", span: 26 },
    { src: "/images/gallery/gallery3.jpg", span: 48 },
    { src: "/images/gallery/gallery4.jpg", span: 26 },
    { src: "/images/gallery/gallery5.jpg", span: 26 },
    { src: "/images/gallery/gallery6.jpg", span: 26 },
    { src: "/images/gallery/gallery7.jpg", span: 26 },
    { src: "/images/gallery/gallery8.jpg", span: 26 },
    { src: "/images/gallery/gallery9.jpg", span: 26 },
    { src: "/images/gallery/gallery10.jpg", span: 26 },
    { src: "/images/gallery/gallery11.jpg", span: 26 },
    { src: "/images/gallery/gallery12.jpg", span: 44 },
    { src: "/images/gallery/gallery13.jpg", span: 22 },
    { src: "/images/gallery/gallery14.jpg", span: 26 },
    { src: "/images/gallery/gallery15.jpg", span: 20 },
    { src: "/images/gallery/gallery16.jpg", span: 26 },
    { src: "/images/gallery/gallery17.jpg", span: 26 },
    { src: "/images/gallery/gallery18.jpg", span: 26 },
    { src: "/images/gallery/gallery19.jpg", span: 26 },
    { src: "/images/gallery/gallery20.jpg", span: 26 },
    { src: "/images/gallery/gallery21.jpg", span: 26 },
    { src: "/images/gallery/gallery22.jpg", span: 26 },
    { src: "/images/gallery/gallery23.jpg", span: 26 },
    { src: "/images/gallery/gallery24.jpg", span: 26 },
    { src: "/images/gallery/gallery25.jpg", span: 26 },
    { src: "/images/gallery/gallery26.jpg", span: 26 },
    { src: "/images/gallery/gallery27.jpg", span: 26 },
    { src: "/images/gallery/gallery28.jpg", span: 26 },
    { src: "/images/gallery/gallery29.jpg", span: 26 },
    { src: "/images/gallery/gallery30.jpg", span: 26 },
    { src: "/images/gallery/gallery31.jpg", span: 26 },
    { src: "/images/gallery/gallery32.jpg", span: 26 },
    { src: "/images/gallery/gallery33.jpg", span: 26 },
    { src: "/images/gallery/gallery34.jpg", span: 26 },
  ]

  // const array = Array.from({ length: 4 }, (_, i) => i + 1)
  const gallery = images.map((ut, i) => {
    return (
      <div
        className={styles.album__item}
        key={i}
        style={{ gridRowEnd: `span ${ut.span}` }}
      >
        <Fade delay={i * 150}>
          <img src={ut.src} alt="" />
          {/* <img src={ut.src} alt="" ref={el => (ref.current[i] = el)} /> */}
        </Fade>
      </div>
    )
  })

  // useEffect(() => {
  //   const height = ref.current
  //   console.log(height)
  //   const setHeight = images.map(i => {
  //     console.log(ref.current)
  //   })
  //   ref.current.addEventListener("load", () => {
  //     console.log(ref.current)
  //   })
  // }, [])

  // const gallery2 = []
  // for (let i = 12; i <= 22; ++i) {
  //   gallery2.push(
  //     <Fade delay={i * 50} key={i}>
  //       <div className={styles.album__item}>
  //         <img
  //           src={require(`../../assets/images/gallery/gallery${i}.jpg`)}
  //           alt=""
  //         />
  //       </div>
  //     </Fade>
  //   )
  // }
  // const gallery3 = []
  // for (let i = 23; i <= 34; ++i) {
  //   gallery3.push(
  //     <Fade delay={i * 50} key={i}>
  //       <div className={styles.album__item}>
  //         <img
  //           src={require(`../../assets/images/gallery/gallery${i}.jpg`)}
  //           alt=""
  //         />
  //       </div>
  //     </Fade>
  //   )
  // }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2>箕澤屋アルバム</h2>
        <div className={styles.lead}></div>
        <div className={styles.album}>
          {gallery}
          {/* <div className={styles.album__row}>{gallery}</div>
          <div className={styles.album__row}>{gallery2}</div>
          <div className={styles.album__row}>{gallery3}</div> */}
        </div>
      </div>
    </div>
  )
}
